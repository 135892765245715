<template>
  <top></top>
  <banner v-model:list="list"></banner>
  <div class="gtype_con" id="gtype_con">
    <ul class="columns">
      <a href="#gtype_1"
        ><li class="flex">
          <div class="flex">
            <span class="icon_web">
              <i></i>
            </span>
            网游类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
    </ul>

    <ul class="columns">
      <a href="#gtype_2"
        ><li class="flex">
          <div class="flex">
            <span class="icon_juese">
              <i></i>
            </span>
            角色类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_3"
        ><li class="flex">
          <div class="flex">
            <span class="icon_maoxian">
              <i></i>
            </span>

            冒险类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_4"
        ><li class="flex">
          <div class="flex">
            <span class="icon_dongzuo"><i></i></span>
            动作类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_5"
        ><li class="flex">
          <div class="flex">
            <span class="icon_sheji"> <i></i></span>
            射击类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
    </ul>
    <ul class="columns">
      <a href="#gtype_6"
        ><li class="flex">
          <div class="flex">
            <span class="icon_celue">
              <i></i>
            </span>
            策略类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_7"
        ><li class="flex">
          <div class="flex">
            <span class="icon_tafang">
              <i></i>
            </span>
            塔防类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_8"
        ><li class="flex">
          <div class="flex">
            <span class="icon_yizhi">
              <i></i>
            </span>
            益智类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_9"
        ><li class="flex">
          <div class="flex">
            <span class="icon_xiuxian">
              <i></i>
            </span>
            休闲类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
    </ul>
    <ul class="columns">
      <a href="#gtype_10"
        ><li class="flex">
          <div class="flex">
            <span class="icon_qipai">
              <i></i>
            </span>
            棋牌类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_11"
        ><li class="flex">
          <div class="flex">
            <span class="icon_jingying">
              <i></i>
            </span>
            经营类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_12"
        ><li class="flex">
          <div class="flex">
            <span class="icon_nvsheng">
              <i></i>
            </span>
            女生类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
      <a href="#gtype_13"
        ><li class="flex">
          <div class="flex">
            <span class="icon_ertong">
              <i></i>
            </span>
            儿童类
          </div>
          <div class="flex jiantou"><span class="icon-jiantou"></span></div></li
      ></a>
    </ul>
  </div>
  <fnav></fnav>
</template>

<script>
import top from "../../components/top.vue";
import banner from "../../components/banner.vue";
import fnav from "../../components/fnav.vue";

export default {
  name: "TypeIndex",
  components: {
    top,
    banner,
    fnav,
  },
  setup() {
    return {
      showuserimg: true,
      showlogo: false,
    };
  },
};
</script>

<style>
</style>