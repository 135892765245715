<template>
  <div class="banner">
    <swiper
      :autoplay="swiper_option.autoplay"
      :loop="swiper_option.loop"
      :speed="swiper_option.speed"
      :pagination="swiper_option.pagination"
      :space-between="10"
    >
      <!-- <swiper-slide v-for="(item, index) in list" :key="index"
        ><img v-bind:src="item.pic"
      /></swiper-slide> -->
      <swiper-slide
        ><img src="@/assets/images/banner/banner1.jpg"
      /></swiper-slide>
      <swiper-slide
        ><img src="@/assets/images/banner/banner2.jpg"
      /></swiper-slide>

    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper";
SwiperCore.use([Autoplay, Pagination]);

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

export default {
  name:'banner',
  props: {

    list: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {};
  },
  components: {
    Swiper,
    SwiperSlide,
  },

  methods:{
    
    

  },
  setup() {
    
    const swiper_option = reactive({
      autoplay: {
        delay: 100000,
        disableOnInteraction: false,
      },
      loop: true,
      speed: 500,
      pagination: {
        clickable: true,
      },
    });
    return {
      swiper_option,

    };
  },
};
</script>

<style >
.banner {
  width: 750px;
  height: 260px;
  overflow: hidden;
}
.banner img {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
}
.swiper-pagination {
  text-align: right;
}
</style>
